import './App.css';
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Header from './Header';
import HomePage from './HomePage';
import About from './About';
import Contact from './Contact';
import Recipe from './Recipe';
import Shop from './Shop';
import Footer from './Footer';

const App = () => {
  const [searchTerm, setSearchTerm] = useState('');

  return (
    <div className="app">
      <Router>
        <Header searchTerm={searchTerm} setSearchTerm={setSearchTerm} />

        <Routes>
          <Route exact path="/" element={<HomePage searchTerm={searchTerm} />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/recipe/:recipe" element={<Recipe />} />
          <Route path="/shop" element={<Shop />} />
        </Routes>

        <Footer />
      </Router>
    </div>
  );
}

export default App;
