export const Recipes = [
  {
    name: 'Brisket Breakfast Hash',
    url: '/recipe/Brisket_Breakfast_Hash',
    img_url: '/images/recipes/brisket_breakfast_hash.png',
    prep_time: 10,
    cook_time: 20,
    servings: 1,
    description: 'Comforting, hardy, and nutrient dense! It is the perfect one-skillet hash to use up leftover brisket, or any other protein you might have in your fridge.',
    ingredients: [
      { amount: 3.5, text: 'oz cooked pulled beef brisket' },
      { amount: 2, text: 'eggs' },
      { amount: 1, text: 'any color bell pepper, sliced' },
      { amount: 1, text: 'yellow onion, sliced' },
      { amount: 3, text: 'garlic cloves, minced' },
      { amount: 1, text: 'tsp smoked paprika' },
      { amount: 0.5, text: 'tsp black pepper' },
      { amount: 0.5, text: 'tsp ground turmeric' },
      { amount: 1, text: 'tsp salt, or to taste' }
    ],
    garnishes: [
      { amount: 50, text: 'g avocado' },
      { text: 'Broccoli sprouts' }
    ],
    instructions: [
      'Heat up the leftover brisket in a cast iron skillet (you can substitute chicken sausage, pulled pork, or any protein you have in the fridge). This allows the fat from the meat to grease the pan and add flavor without adding any cooking oil. Then transfer to a plate.',
      'Add onion and bell pepper to the skillet. Cook for 3 minutes or until soft.',
      'Add minced garlic. Cook for 1 minute.',
      'Add the brisket back into the skillet.',
      'Use the back of your spatula to make two wells in the mixture and crack an egg into each well. Cook the eggs to your liking. Optionally cover the pan with a lid.',
      'Garnish with avocado and broccoli sprouts.'
    ],
    nutrition: [
      { type: 'CALORIES', amount: 510, unit: 'KCAL' },
      { type: 'CARBOHYDRATES', amount: 14, unit: 'G' },
      { type: 'PROTEIN', amount: 32, unit: 'G' },
      { type: 'FAT', amount: 35, unit: 'G' },
      { type: 'FIBER', amount: 5, unit: 'G' },
    ]
  },
  {
    name: 'Lobster Pesto Summer Bowl',
    url: '/recipe/Lobster_Pesto_Summer_Bowl',
    img_url: '/images/recipes/lobster_summer_bowl.png',
    prep_time: 5,
    cook_time: 7,
    servings: 1,
    description: 'Simple lobster meat paired with pistachio pesto, eggs, tomatoes, avocado, and greens. This simple and refreshing lobster salad is light but packed with high quality protein and micronutrients. It is summer in a bowl.',
    ingredients: [
      { amount: 6, text: 'oz lobster meat, steamed ' },
      { amount: 2, text: 'eggs, soft boiled' },
      { amount: 85, text: 'g any salad base (I am using a premade power green salad base)' },
      { amount: 100, text: 'g cherry tomatoes, halved' },
      { amount: 50, text: 'g avoado, sliced' },
      { amount: 2, text: 'g', link: { text: 'Pistachio Pesto', href: '/recipe/Pistachio_Pesto' } },
    ],
    garnishes: [
      { text: 'Fresh basil leaves' },
      { text: 'Fresh parsley leaves' },
      { text: 'Broccoli sprouts' },
      { text: 'Nuts and seeds' },
      { text: 'A drizzle of sriracha' },
    ],
    instructions: [
      'Warm up the lobster meat in a steamer or microwave',
      'Assemble the salad, pour the pistachio pesto dressing over, and enjoy!',
    ],
    nutrition: [
      { type: 'CALORIES', amount: 490, unit: 'KCAL' },
      { type: 'CARBOHYDRATES', amount: 13, unit: 'G' },
      { type: 'PROTEIN', amount: 49, unit: 'G' },
      { type: 'FAT', amount: 24, unit: 'G' },
      { type: 'FIBER', amount: 7, unit: 'G' },
    ]
  },
  {
    name: 'Chicken Shwarma Bowl',
    url: '/recipe/Chicken_Shwarma_Bowl',
    img_url: '/images/recipes/chicken_shwarma_bowl.png',
    prep_time: 40,
    cook_time: 5,
    servings: 4,
    description: 'If you are craving chicken shawarma and also the freshness of a summer bowl, this is the recipe for you. With this simple shawarma marinate, it elevates any leftover chicken you might have in the fridge.',
    ingredient_sections: [
      {
        title: 'Salad',
        ingredients: [
          { amount: 400, text: 'g cooked chicken, chopped, marinated with shawarma marinate' },
          { amount: 4, text: 'eggs, soft boiled' },
          { amount: 400, text: 'g any salad base (I am using a premade power green salad base)' },
          { amount: 400, text: 'g cherry tomatoes, halved' },
          { amount: 200, text: 'g avoado, sliced' }
        ]
      },
      {
        title: 'Chicken shawarma marinate',
        ingredients: [
          { amount: 2, text: 'tsp salt' },
          { amount: 2, text: 'tbsp smoked paprika' },
          { amount: 1, text: 'tsp ground coriander' },
          { amount: 1, text: 'tsp cayenne pepper' },
          { amount: 1, text: 'tsp ground turmeric' },
          { amount: 1, text: 'tsp ground black pepper' },
          { amount: 0.25, text: 'tsp ground cinnamon' },
          { amount: 2, text: 'tbsp minced garlic' },
          { amount: 2, text: 'tsp lemon zest' },
          { amount: 0.33, text: 'cup olive oil' },
          { amount: 6, text: 'tbsp lemon juice' }
        ]
      }
    ],
    garnishes: [
      { text: 'Chopped parsley' },
      { text: 'Broccoli sprouts' },
    ],
    instructions: [
      ''
    ],
    tips: [
      'I recommend marinating cooked chicken for at least 30 min, preferably overnight.',
      'You can use any kind of leftover chicken, breast meat or dark meat. '
    ],
    nutrition: [
      { type: 'CALORIES', amount: 198, unit: 'KCAL' },
      { type: 'CARBOHYDRATES', amount: 29, unit: 'G' },
      { type: 'PROTEIN', amount: 6, unit: 'G' },
      { type: 'FAT', amount: 7, unit: 'G' },
      { type: 'SATURATED FAT', amount: 1, unit: 'G' },
      { type: 'SODIUM', amount: 412, unit: 'MG' },
    ]
  },
  {
    name: 'Ham Steak Power Lunch',
    url: '/recipe/Ham_Steak_Power_Lunch',
    img_url: '/images/recipes/ham_steak_power_lunch.png',
    prep_time: 5,
    cook_time: 10,
    servings: 1,
    description: 'This is a diner style breakfast plate. With a minimum amount of cooking and assembling, it packs an impressive 63g of protein with healthy fats and slow burning carbs to fuel your day!',
    ingredients: [
      { amount: 6, text: 'oz ham steak' },
      { amount: 2, text: 'eggs' },
      { amount: 100, text: 'g cherry tomatoes' },
      { amount: 50, text: 'g cottage cheese (I am using 2% cottage cheese from Good Culture)' },
      { amount: 0.5, text: 'tsp turmeric' },
      { text: 'Salt and pepper to taste' },
    ],
    garnishes: [
      { text: 'Broccoli sprouts' },
      { text: 'Fire Roasted bell pepper strips' },
    ],
    instructions: [
      'In a mixing bowl, whisk together the eggs, cottage cheese, salt, pepper, and turmeric powder.',
      'Spray some cooking oil into a pan and heat on medium low.',
      'Pour the mixture into a pan. Cook until desired level of texture. Set the scramble on to a plate.',
      'In the same cooking pan, warm up the ham steak and tomatoes on medium heat.',
      'Plate everything, add a handful of broccoli sprouts and roasted bell pepper strips (or any greens) on the side for some extra fiber.'
    ],
    tips: [
      'Look for a ham steak that is low in added sugar and sodium.',
      'This meal pairs well with ketchup. I recommend Primal Kitchen ketchup, it has 0 added sugar and it is delicious!',
      'Feel free to add toast or fruit on the side if you want some extra carbs.'
    ],
    nutrition: [
      { type: 'CALORIES', amount: 418, unit: 'KCAL' },
      { type: 'CARBOHYDRATES', amount: 10, unit: 'G' },
      { type: 'PROTEIN', amount: 63, unit: 'G' },
      { type: 'FAT', amount: 14, unit: 'G' },
      { type: 'FIBER', amount: 2, unit: 'G' },
    ]
  },
  {
    name: 'Chicken Hummus Sandwich',
    url: '/recipe/Chicken_Hummus_Sandwich',
    img_url: '/images/recipes/chicken_sandwich.png',
    prep_time: 10,
    cook_time: 5,
    servings: 4,
    description: 'This Mediterranean inspired chicken sandwich is packed with flavor, protein, and healthy fats. You can make a quick hummus and eggplant spread from scratch (links included in this recipe), or you can take a short cut by using store brought hummus and baba ghanoush (or eggplant spread). It is quick to assembly and meal prep friendly.',
    ingredients: [
      { amount: 340, text: 'g rotisserie chicken, shredded' },
      { amount: 200, text: 'g', link: { text: 'Roasted Red Bell Pepper Hummuss', href: '/recipe/Red_Pepper_Hummus' } },
      { amount: 280, text: 'g (1 can) smoky eggplant spread' },
      { amount: 4, text: 'ciabatta rolls' },
      { amount: 4, text: 'slices of cheese of your choice' },
    ],
    instructions: [
      'In a mixing bowl, combine the rotisserie chicken and the hummus. Coat the chicken mixture evenly.',
      'Slice the ciabatta rolls and spread a layer of the hummus chicken mixture on to the bread.',
      'Add a layer of eggplant spread on the inside of the top bun.',
      'Place a slice of cheese on top of the hummus chicken mixture.',
    ],
    tips: [
      'You can use any sandwich bread. I am using ciabatta rolls because I find it holds its structure when prepped ahead and left in the fridge for up to 1 week.',
      'Storage and reheating: Wrap in tinfoil and store in the fridge for up to 1 week. Heat up in the oven at 400 for 5 min.'
    ],
    nutrition: [
      { type: 'CALORIES', amount: 664, unit: 'KCAL' },
      { type: 'CARBOHYDRATES', amount: 47, unit: 'G' },
      { type: 'PROTEIN', amount: 45, unit: 'G' },
      { type: 'FAT', amount: 31, unit: 'G' },
      { type: 'FIBER', amount: 4, unit: 'G' },
    ]
  },
  {
    name: 'Blueberry Greek Yogurt',
    url: '/recipe/Blueberry_Greek_Yogurt',
    img_url: '/images/recipes/greek_yogurt_bowl.png',
    prep_time: 1,
    cook_time: 0,
    servings: 1,
    description: 'This has been my breakfast obsession for the past few years. I wake up everyday looking forward to it! No cooking or meal prepping required. It is truly like having dessert for breakfast with a balance of protein, carbs, and micronutrients to start your morning.',
    ingredients: [
      { amount: 170, text: 'g 2% fat plain greek yogurt' },
      { amount: 70, text: 'g blueberry, frozen' },
      { amount: 10, text: 'g cacao nibs' }
    ],
    instructions: [
      'Simply place everything into a bowl, crush some of the frozen blueberries with a spoon, mix, and enjoy!'
    ],
    tips: [
      'I am using 2% greek yogurt from a local shop. It is made from sheep milk and goat milk. Feel free to use any brand of greek yogurt you have access to in your grocery store. I find 2% fat more flavorful than nonfat. But any thick greek yogurt will work great in this recipe.',
      'It is important to use frozen blueberries. I find it helps to thicken the yogurt into an ice-cream-like texture after mixing.'
    ],
    nutrition: [
      { type: 'CALORIES', amount: 298, unit: 'KCAL' },
      { type: 'CARBOHYDRATES', amount: 25, unit: 'G' },
      { type: 'PROTEIN', amount: 25, unit: 'G' },
      { type: 'FAT', amount: 10, unit: 'G' },
      { type: 'FIBER', amount: 5, unit: 'G' },
    ]
  },
  {
    name: 'Pistachio Pesto',
    url: '/recipe/Pistachio_Pesto',
    img_url: '/images/recipes/pistachio_pesto.png',
    prep_time: 10,
    cook_time: 0,
    servings: 8,
    description: 'This pistachio pesto has such a vibrant color thanks to the pistachio, basil, and parsley. Unlike traditional pesto, it does not need any parmigiano reggiano. It’s a great vegan pesto for the summer!',
    ingredients: [
      { amount: 1, text: 'cup shelled unsalted raw pistachio' },
      { amount: 1, text: 'cup fresh basil leaves' },
      { amount: 1, text: 'cup fresh parsley leaves' },
      { amount: 4, text: 'cloves of garlic' },
      { amount: 0.25, text: 'cups extra virgin olive oil' },
      { amount: 3, text: 'tbsp lemon juice' },
      { amount: 1, text: 'tbsp salt' },
      { amount: 0.5, text: 'tbsp black pepper' },
    ],
    instructions: [
      'Add the raw pistachios into a food processor. Blend until the texture becomes like a paste.',
      'Add the fresh basil leaves, parsley leaves, garlic cloves, lemon juice, and black pepper into the food processor. Blend until there are no more big chunks.',
      'Slowly add the olive oil into the food processor. Blend until desired smoothness.'
    ],
    nutrition: [
      { type: 'CALORIES', amount: 150, unit: 'KCAL' },
      { type: 'CARBOHYDRATES', amount: 5, unit: 'G' },
      { type: 'PROTEIN', amount: 3, unit: 'G' },
      { type: 'FAT', amount: 13, unit: 'G' },
      { type: 'FIBER', amount: 2, unit: 'G' },
    ]
  },
  {
    name: 'Roasted Red Bell Pepper Hummus',
    url: '/recipe/Red_Pepper_Hummus',
    img_url: '/images/recipes/red_pepper_hummus.png',
    prep_time: 10,
    cook_time: 0,
    servings: 8,
    description: 'This roasted red bell pepper hummus can be ready in 10 minutes if you take some of the short cuts below. It is so versatile as a dip to go with veggies and chips, or to be used as a spread for toast or sandwiches.',
    ingredients: [
      { amount: 2, text: '(16 oz) cans cooked chickpeas, drained' },
      { amount: 1, text: '(16 oz) jar roasted red bell pepper, drained' },
      { amount: 0.5, text: 'lemon, juiced' },
      { amount: 1, text: 'tbsp tahini, or more if you prefer a creamier texture' },
      { amount: 1, text: 'tbsp olive oil, or more if you prefer a smoother texture' },
      { amount: 2, text: 'tsp salt, or to taste' },
      { amount: 1, text: 'tsp smoked paprika' },
      { amount: 1, text: 'tsp garlic powder' },
      { amount: 0.5, text: 'tbsp black pepper' },
    ],
    garnishes: [
      { text: 'Any nuts and seeds you have in your pantry. I’m using white sesame seeds, pumpkin seeds, and pine nuts.' },
    ],
    instructions: [
      'Add all the ingredients into a blender and blend.',
      'Plate & garnish.'
    ],
    nutrition: [
      { type: 'CALORIES', amount: 130, unit: 'KCAL' },
      { type: 'CARBOHYDRATES', amount: 19, unit: 'G' },
      { type: 'PROTEIN', amount: 5, unit: 'G' },
      { type: 'FAT', amount: 4, unit: 'G' },
      { type: 'FIBER', amount: 6, unit: 'G' },
    ]
  },
  {
    name: 'Mala Spicy Pot',
    url: '/recipe/Mala_Spicy_Pot',
    img_url: '/images/recipes/mala_spicy_pot.png',
    prep_time: 30,
    cook_time: 15,
    servings: 2,
    description: 'This is inspired by my favorite childhood Chinese dish, Mala Spicy Pot. It is traditional Chinese stir fry dish that is both spicy and numbing due to the peppercorn. I modified the dish to remove the numbing flavor and simplified the cooking process so it comes together quickly.',
    ingredients: [
      { amount: 4, text: 'oz luncheon meat (Spam),  sliced' },
      { amount: 300, text: 'g green beans' },
      { amount: 100, text: 'g Enoki mushroom' },
      { amount: 300, text: 'g golden potatoes, sliced' },
      { amount: 10, text: 'g black fungus, soak ahead of time' },
      { amount: 5, text: 'dry red bell peppers' },
      { amount: 5, text: 'slices of ginger' },
      { amount: 1, text: 'tbsp black bean paste' },
      { amount: 1, text: 'tbsp soy sauce, or to taste' },
      { amount: 0.5, text: ' tsp salt, or to taste' },
    ],
    garnishes: [
      { text: 'White sesame seeds' },
      { text: 'Sliced scallions' },
    ],
    instructions: [
      'Heat up a large pot of water and bring it to a boil.',
      'Put in the black fungus.',
      'After 2 minutes, put in the Enoki mushrooms.',
      'After another 2 minutes, put in the green beans.',
      'After another 2 minutes, put in the potatoes.',
      'Let the pot boil for another 4 minutes, the drain everything from the pot and set it aside.',
      'In a large pan, lightly coat with cooking oil. Add in the spam, cook at medium heat until lightly caramelized on both sides. Set it aside.',
      'In the same pan, add dry red pepper and ginger, cook for 1 min.',
      'Add the drained pre-boiled veggies back into the pan, add in black bean paste, soy sauce, and salt. Toss until everything is evenly coated with seasoning.',
      'Add the cooked spam back in and cook for another minute.',
      'Plate and Serve with rice.'
    ],
    tips: [
      'Black fungus can be found in most asian markets in a dried format. It needs to be soaked in water ahead of time to expand and soften. I recommend soaking them in a bowl for at least 30 minutes. It does have a unique crunchy texture, omit if you dislike it.'
    ],
    nutrition: [
      { type: 'CALORIES', amount: 395, unit: 'KCAL' },
      { type: 'CARBOHYDRATES', amount: 52, unit: 'G' },
      { type: 'PROTEIN', amount: 17, unit: 'G' },
      { type: 'FAT', amount: 16, unit: 'G' },
      { type: 'FIBER', amount: 12, unit: 'G' },
    ]
  }
];
