import './Shop.css'
import { useState } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';

const Tabs = {
  All: { label: 'All', title: 'All Products' },
  Kitchen: { label: 'Kitchen', title: 'Kitchen Essentials' },
  Pantry: { label: 'Pantry', title: 'Pantry Staples' },
}

const ShopItems = [
  { name: 'Navitas Organics Raw Cacao Nibs', link: 'https://amzn.to/3xn8J8Z', img: 'images/shop/cacao_nibs.png', type: 'Pantry' },
  { name: 'Kevala Organic Sesami Tahini', link: 'https://amzn.to/43KVcE1', img: 'images/shop/tahini.png', type: 'Pantry' },
  { name: 'Manitoba Harvest Hemp Hearts', link: 'https://amzn.to/3vE9ObU', img: 'images/shop/hemp_hearts.png', type: 'Pantry' },
  { name: 'Carrington Farms Organic Chia Seeds', link: 'https://amzn.to/49mmoud', img: 'images/shop/chia_seeds.png', type: 'Pantry' },
  { name: 'Vitamix Explorian Blender', link: 'https://amzn.to/3TLX5Mn', img: 'images/shop/vitamix.png', type: 'Kitchen' },
  { name: 'Navitas Organics Organic Cacao Powder', link: 'https://amzn.to/3VJHteN', img: 'images/shop/cacao_powder.png', type: 'Pantry' },
  { name: 'San-J Tamari Lite Soy Sauce', link: 'https://amzn.to/4aj4OZm', img: 'images/shop/soy_sauce.png', type: 'Pantry' },
  { name: 'Thai Kitchen Red Curry Paste', link: 'https://amzn.to/3TIWZVR', img: 'images/shop/curry_paste.png', type: 'Pantry' },
];

const Shop = () => {
  const [selectedTab, setSelectedTab] = useState(Tabs.All);
  const [searchTerm, setSearchTerm] = useState('');

  const onSearchChange = event => {
    setSearchTerm(event.target.value);
    if (selectedTab !== Tabs.All) {
      setSelectedTab(Tabs.All);
    }
  }

  const shopFilter = item => {
    const searchCondition = !searchTerm || item.name.toLowerCase().includes(searchTerm.toLowerCase());
    const tabCondition = selectedTab.label === 'All' || item.type === selectedTab.label;
    return searchCondition && tabCondition;
  }

  const theme = createTheme({
    palette: {
      primary: {
        main: '#7e8a95',
      },
    },
  });

  return (
    <div className="shop">
      <div className="shop-header">
        <img className="shop-header-image" src="images/quebec.png" alt="Shop" />
        <div className="title-mask"></div>
        <div className="shop-title title-text">Shop</div>
      </div>
      <TextField className="search-input"
        label="Search..."
        variant="outlined"
        size="small"
        value={searchTerm}
        onChange={onSearchChange}
        InputProps={{ sx: { borderRadius: 50 } }}
      />
      <div className="chip-container">
        {Object.values(Tabs).map(tab =>
          <ThemeProvider key={tab.label} theme={theme}>
            <Chip className="tab-chip" color="primary" label={tab.label} variant={tab === selectedTab ? '' : 'outlined'} onClick={() => setSelectedTab(tab)} />
          </ThemeProvider>
        )}
      </div>
      <div className="shop-sub-title title-text">{selectedTab.title}</div>
      <div className="shop-description">
        Here are some products we love. The affiliate links take you to Amazon.com where they can be purchased.
      </div>
      <div className="border"></div>
      <div className="shop-item-container">
        {ShopItems.filter(shopFilter).map((item, i) =>
          <a key={i} className="shop-item" href={item.link} target="_blank" rel="noopener noreferrer">
            <img className="item-image" src={item.img} alt={item.name} />
            <div className="item-text">{item.name}</div>
          </a>
        )}
      </div>
    </div>
  )
}

export default Shop;
