import './Contact.css';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import emailjs from "@emailjs/browser";

const Contact = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [showSnackbar, setShowSnackbar] = useState(false);

  useEffect(() => {
    emailjs.init('o2Z6vgHSALnIiKzzK');
  }, []);

  const onSendClick = async () => {
    await emailjs.send('service_2mt99aa', 'template_z0aicks', {
      name, email, message
    });
    setName('');
    setEmail('');
    setMessage('');
    setShowSnackbar(true);
  }

  const canSend = () => {
    return name && email && message;
  }

  return (
    <div className="contact">
      <img className="contact-image" src="/images/rome.png" alt="Contact" />
      <div className="contact-text-container">
        <div className="contact-title">Contact Siyu</div>
        <div className="contact-email">siyuskitchen@gmail.com</div>
        <TextField className="input"
          label="Name"
          variant="outlined"
          size="small"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <TextField className="input"
          label="Email"
          variant="outlined"
          size="small"
          value={email}
          onChange={e => setEmail(e.target.value)}
        />
        <TextField className="input"
          label="Message"
          variant="outlined"
          size="small"
          value={message}
          onChange={e => setMessage(e.target.value)}
          multiline={true}
          minRows={5}
          maxRows={8}
        />
        <Button className="send-button"
          variant="outlined"
          onClick={onSendClick}
          disabled={!canSend()}>
          Send
        </Button>

        <Snackbar className="snackbar"
          open={showSnackbar}
          autoHideDuration={5000}
          onClose={() => setShowSnackbar(false)}
          ContentProps={{ sx: { width: 100 } }}
          message={
            <div className="snackbar-content">
              <span className="material-symbols-outlined">done</span>
              Email Sent.
            </div>
          }
        />
      </div>
    </div>
  );
}

export default Contact;
