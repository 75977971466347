import './Footer.css'

const Footer = () => {
  return (
    <div className="footer">
      <div className="logo-container title-text">
        S
        <img className="avocado-icon" src="/avocado_icon.png" alt="avocado" />
        K
      </div>
      2024
    </div>
  );
}

export default Footer;