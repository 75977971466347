import './Recipe.css'
import { useState, useEffect } from 'react';
import { Recipes } from './recipes';

const Recipe = () => {
  const [loading, setLoading] = useState(true);
  const [recipe, setRecipe] = useState(null);
  const [multiplier, setMultiplier] = useState(1);

  useEffect(() => {
    const url_recipe = Recipes.find(
      recipe => window.location.pathname === recipe.url
    );
    setRecipe(url_recipe);
    setLoading(false);
  }, []);

  if (loading) return null;

  if (!recipe) {
    return (
      <div className="error-container">
        <div className="error-title">Oops!</div>
        <div className="error-text">Recipe Not Found</div>
      </div>
    );
  }

  return (
    <div className="recipe">
      <img className="recipe-image" src={recipe.img_url} alt={recipe.name} />
      <div className="recipe-card">

        <div className="recipe-name">{recipe.name}</div>

        <div className="info-container">
          <span className="material-symbols-outlined clock-icon">schedule</span>
          <div className="time-item">
            <div className="time-title">PREP:</div>
            <div className="time-text">{recipe.prep_time} min{recipe.prep_time === 1 ? '' : 's'}</div>
          </div>
          <div className="time-item">
            <div className="time-title">COOK:</div>
            <div className="time-text">{recipe.cook_time} min{recipe.cook_time === 1 ? '' : 's'}</div>
          </div>
          <div className="time-item">
            <div className="time-title">TOTAL:</div>
            <div className="time-text">{recipe.prep_time + recipe.cook_time} min{(recipe.prep_time + recipe.cook_time) === 1 ? '' : 's'}</div>
          </div>
        </div>

        <div className="info-container">
          <span className="material-symbols-outlined servings-icon">restaurant</span>
          <div className="servings-title">SERVINGS:</div>
          <div className="servings-text">{multiplier * recipe.servings} serving{(multiplier * recipe.servings) === 1 ? '' : 's'}</div>
        </div>

        <div className="recipe-description-container">
          <div className="description-title">Description</div>
          <div className="description-text">{recipe.description}</div>
        </div>

        <div className="recipe-description-container">
          <div className="description-title">
            Ingredients
            <span className={'multiplier' + (multiplier === 3 ? ' selected' : '')} onClick={() => setMultiplier(3)}>3X</span>
            <span className={'multiplier' + (multiplier === 2 ? ' selected' : '')} onClick={() => setMultiplier(2)}>2X</span>
            <span className={'multiplier' + (multiplier === 1 ? ' selected' : '')} onClick={() => setMultiplier(1)}>1X</span>
          </div>
          {recipe.ingredients && (
            <div className="description-text">
              <ul className="ingredient-list">
                {recipe.ingredients.map((ingredient, i) =>
                  <li className="ingredient" key={i}>
                    {ingredient.amount ? (multiplier * ingredient.amount) : ''} {ingredient.text}&nbsp;
                    {ingredient.link ? <a className="recipe-link" href={ingredient.link.href} target="_blank" rel="noopener noreferrer">{ingredient.link.text}</a> : null}
                  </li>
                )}
              </ul>
            </div>
          )}
          {recipe.ingredient_sections && (
            <>
              {recipe.ingredient_sections.map((section, i) =>
                <div className="recipe-description-container" key={`section_${i}`}>
                  <div className="description-sub-title">{section.title}</div>
                  <div className="description-text">
                    <ul className="ingredient-list">
                      {section.ingredients.map((ingredient, i) =>
                        <li className="ingredient" key={`${section.title}_${i}`}>
                          {ingredient.amount ? (multiplier * ingredient.amount) : ''} {ingredient.text}&nbsp;
                          {ingredient.link ? <a className="recipe-link" href={ingredient.link.href} target="_blank" rel="noopener noreferrer">{ingredient.link.text}</a> : null}
                        </li>
                      )}
                    </ul>
                  </div>

                </div>
              )}
            </>
          )}
        </div>

        {recipe.garnishes && recipe.garnishes.length &&
          <div className="recipe-description-container">
            <div className="description-sub-title">
              Optional Garnishes
            </div>
            <div className="description-text">
              <ul className="ingredient-list">
                {recipe.garnishes.map((garnish, i) =>
                  <li className="ingredient" key={i}>{garnish.amount ? (multiplier * garnish.amount) : ''} {garnish.text}</li>
                )}
              </ul>
            </div>
          </div>
        }

        <div className="recipe-description-container">
          <div className="description-title">Insrtuctions</div>
          <div className="description-text">
            <ol>
              {recipe.instructions.map((instruction, i) =>
                <li className="instruction" key={i}>{instruction}</li>
              )}
            </ol>
          </div>
        </div>

        {recipe.tips && recipe.tips.length &&
          <div className="recipe-description-container">
            <div className="description-title">Tips</div>
            <div className="description-text">
              <ol>
                {recipe.tips.map((tip, i) =>
                  <li className="instruction" key={i}>{tip}</li>
                )}
              </ol>
            </div>
          </div>
        }

        <div className="recipe-description-container">
          <div className="description-title">Nutrition</div>
          <div className="nutrition-text">
            {recipe.nutrition.map((item, i) =>
              <span className="nutrient" key={i}>
                <span className="n-type">{item.type}: </span>
                <span>{multiplier * item.amount}{item.unit}</span>
                {i === recipe.nutrition.length - 1 ? '' : ' | '}
              </span>
            )}
          </div>
        </div>

      </div>
    </div>
  );
}

export default Recipe;
