import './About.css'

const About = () => {
  return (
    <div className="about">
      <div className="profile">
        <img className="profile-image" src="/images/siyu_profile.png" alt="Siyu" />
        <div className="profile-text-container">
          <div className="profile-title">Hello There!</div>
          <div className="profile-text">My name is Siyu. I'm passionate about creating healthy dishes while focusing on wholesome ingredients that nourish our body and soul. My goal is to create meals that are simple, balanced, nutrient dense, and most importantly, delicious! With small tweaks and changes to traditional recipes, healthy living can be made simple. There is an inner chef in every one of us and I am so happy to share mine with you.</div>
        </div>
      </div>
      <div className="team-container">
        <div className="team-title">Meet the rest of the team!</div>
        <div className="team-member">
          <img className="profile-image" src="/images/ryan_profile.png" alt="Ryan" />
          <div className="member-text">
            <div className="member-text">Ryan</div>
            <div className="member-description">Web Developer</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
