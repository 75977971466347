import './Header.css';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';

const Header = ({ searchTerm, setSearchTerm }) => {
  const shouldShowMenu = () => {
    return document.documentElement.clientWidth >= 800;
  }

  const navigate = useNavigate();
  const [showSearch, setShowSearch] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [showMenu, setShowMenu] = useState(shouldShowMenu());

  useEffect(() => {
    const onResize = () => setShowMenu(shouldShowMenu());
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize)
    }
  }, []);

  const onSearchIconClick = () => {
    setShowSearch(!showSearch);
    setSearchTerm('');
  }

  const onSearchChange = (event) => {
    setSearchTerm(event.target.value);
  }

  const onShopClick = () => {
    navigate('/shop');
  }

  const onAboutClick = () => {
    navigate('/about');
  }

  const onMailClick = () => {
    navigate('/contact');
  }

  const onMenuClick = event => {
    setMenuAnchorEl(event.currentTarget);
  }

  const onMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const resetState = () => {
    setShowSearch(false);
    setSearchTerm('');
    navigate('/');
  }

  return (
    <div className="header">
      {(window.location.pathname === '/') ?
        <svg width="1em" height="1em" viewBox="0 0 16 16" className="search-icon" fill="currentColor" onClick={onSearchIconClick}>
          <path fillRule="evenodd" d="M10.442 10.442a1 1 0 0 1 1.415 0l3.85 3.85a1 1 0 0 1-1.414 1.415l-3.85-3.85a1 1 0 0 1 0-1.415z"></path>
          <path fillRule="evenodd" d="M6.5 12a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11zM13 6.5a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0z"></path>
        </svg> :
        <span className="material-symbols-outlined back-icon" onClick={resetState}>
          arrow_back_ios
        </span>
      }
      {showSearch &&
        <TextField className="search-input"
          label="Search..."
          variant="outlined"
          size="small"
          value={searchTerm}
          onChange={onSearchChange}
          InputProps={{ sx: { borderRadius: 50 } }}
          autoFocus={true}
        />
      }
      {(!showSearch || document.documentElement.clientWidth >= 775) &&
        <div className="title title-text" onClick={resetState}>
          Siyu's
          <img className="avocado-icon" src="/avocado_icon.png" alt="avocado" />
          Kitchen
        </div>
      }
      {showMenu ?
        <div className="menu-container">
          <span className="material-symbols-outlined menu-link" onClick={onMailClick}>mail</span>
          <div className="menu-link" onClick={onShopClick}>Shop</div>
          <div className="menu-link" onClick={onAboutClick}>About</div>
        </div> :
        <IconButton className="menu-icon" size="small" onClick={onMenuClick}>
          <span className="material-symbols-outlined">menu</span>
        </IconButton>
      }
      <Menu className="dropdown-menu"
        anchorEl={menuAnchorEl}
        open={menuAnchorEl !== null}
        onClose={onMenuClose}
        onClick={onMenuClose}>
        <MenuItem onClick={onAboutClick}>
          <span className="menu-item">About</span>
        </MenuItem>
        <MenuItem onClick={onShopClick}>
          <span className="menu-item">Shop</span>
        </MenuItem>
        <MenuItem onClick={onMailClick}>
          <span className="material-symbols-outlined menu-item">mail</span>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default Header;
