import './HomePage.css';
import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Recipes } from './recipes';

const ROME_IMG_URL = '/images/rome.png';
const ROME_WIDE_IMG_URL = '/images/rome_wide.png';

const HomePage = ({ searchTerm }) => {
  const getImgUrl = () => {
    return document.documentElement.clientWidth <= 600 ? ROME_IMG_URL : ROME_WIDE_IMG_URL;
  }

  const navigate = useNavigate();
  const [imgUrl, setImgUrl] = useState(getImgUrl());

  useEffect(() => {
    const onResize = () => setImgUrl(getImgUrl());
    window.addEventListener("resize", onResize);
    return () => window.removeEventListener("resize", onResize);
  }, []);

  const onMissionClick = () => {
    navigate('/about');
  }

  const onRecipeClick = recipe => {
    navigate(recipe.url);
  }

  const recipeFilter = recipe => {
    return !searchTerm || recipe.name.toLowerCase().includes(searchTerm.toLowerCase());
  }

  return (
    <div className="home-page">
      {!searchTerm &&
        <div className="main-image-container">
          <img className="rome-image" src={imgUrl} alt="rome" />
          <div className="image-mask"></div>
          <div className="image-text-container" onClick={onMissionClick}>
            <div className="mission-header title-text">Mission</div>
            <div className="mission-text">Learn more about our mission to make a healthy lifestyle tasty and accessible for everyone.</div>
          </div>
        </div>
      }
      <div className="section-header title-text">Latest Recipes</div>
      <div className="recipes-container">
        {Recipes.filter(recipeFilter).map((recipe, i) =>
          <div className="recipe-preview" key={i} onClick={() => onRecipeClick(recipe)}>
            <img className="recipe-image" src={recipe.img_url} alt={recipe.name} />
            <div className="recipe-text">{recipe.name}</div>
          </div>
        )}
      </div>
    </div>
  );
}

export default HomePage;
